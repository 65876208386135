export default {
	hidden: [] as string[],
	handbags: [
		"2663",
		"2666",
		"2826",
		"2875",
		"2900",
		"2907",
		"2910",
		"2914",
		"2941",
		"2947",
		"3011",
		"3013",
		"3028",
		"3050",
		"3051",
		"3064",
		"3065",
		"3085",
		"3086",
		"3087",
		"3092",
		"3093",
		"3097",
		"3100",
		"3103",
		"3104",
		"3106",
		"3107",
		"3108",
		"3110",
		"3111",
		"3113",
		"3116",
		"3117",
		"3119",
		"3121",
		"3124",
		"3126",
		"3127",
		"3128",
	],
	handbagsWithStraps: [
		"2663",
		"2666",
		"2826",
		"2900",
		"2907",
		"2910",
		"2941",
		"2947",
		"3011",
		"3013",
		"3050",
		"3051",
		"3093",
		"3097",
		"3103",
		"3104",
		"3107",
		"3113",
		"3116",
		"3117",
		"3119",
		"3121",
		"3124",
		"3126",
	],
	luggage: [
		"3009",
		"3136",
		"3140",
		"3141",
		"3142",
		"3145",
		"3146",
		"3147",
		"3148",
		"3149",
	],
	vouchers: ["0001"],
	with360: [
		"2771",
		"2771-03",
		"2826-05",
		"2826-06",
		"2826-07",
		"2875-03",
		"2875-04",
		"2875-05",
		"2900-04",
		"2907-02",
		"2910-04",
		"2910-05",
		"2910-06",
		"2910-07",
		"2910-08",
		"2910-09",
		"2914",
		"2914-11",
		"2914-12",
		"2914-13",
		"2914-14",
		"2947",
		"2947-07",
		"2947-10",
		"3011-10",
		"3011-12",
		"3013",
		"3028-01",
		"3046",
		"3050",
		"3050-29",
		"3050-30",
		"3050-31",
		"3050-35",
		"3050-36",
		"3050-37",
		"3050-38",
		"3050-39",
		"3051-07",
		"3067-01",
		"3085",
		"3085-02",
		"3087",
		"3087-07",
		"3087-08",
		"3097",
		"3097-19",
		"3097-24",
		"3097-25",
		"3097-30",
		"3098",
		"3099-01",
		"3111-03",
		"3113-09",
		"3113-12",
		"3113-14",
		"3118-01",
		"3118-02",
		"3118-03",
		"3119-07",
		"3121-03",
		"3124",
		"3124-08",
		"3124-10",
		"3124-11",
		"3124-12",
		"3124-15",
		"3126",
		"3126-07",
		"3126-08",
		"3126-09",
		"3129",
		"3129-04",
		"3137-01",
		"3138",
	],
	withEmblem: [
		"103",
		"228",
		"254",
		"268",
		"685",
		"686",
		"738",
		"2875",
		"2907",
		"3011",
		"3028",
		"3046",
		"3064",
		"3065",
		"3085",
		"3086",
		"3067",
		"3087",
		"3092",
		"3093",
		"!3011-10",
		"!3011-11",
		"!3011-12",
		"3100",
		"3110",
		"3127",
		"3128",
		"3129",
		"!3129-04",
		"!3129-05",
	],
	withCrystalEmblem: ["2663", "2666", "2910", "!2910-04", "!2910-05", "3051"],
	withPassportFoiling: ["670"],
	withPhoneSize: ["244", "278"],
	withLining: ["3127"],
	withSingleGoldCorners: ["486", "785", "874", "882"],
	withStrapFitting: ["010", "3050", "!3050-06", "3097", "3103"],
	withPalladiumFittings: [
		"3011-10",
		"3011-11",
		"3011-12",
		"3113-12",
		"3113-13",
		"3113-14",
		"3129-04",
		"3129-05",
	],
	withStrapStyle: ["2666"],
	withTabletSize: ["245"],
	withWalletSize: ["805"],
	withPreview: ["686", "805", "2914", "3050", "3085", "3087", "3097", "3113"],
	withInternalImage: [
		"103",
		"112",
		"201",
		"202",
		"203",
		"204",
		"205",
		"206",
		"228",
		"254",
		"268",
		"399",
		"477",
		"478",
		"486",
		"610",
		"620",
		"659",
		"685",
		"686",
		"717",
		"738",
		"794",
		"805",
		"806",
		"882",
		"925",
		"975",
	],
};
