import {
	Explore,
	Instagram,
	ProductCarousel,
	Row,
	ThreeSixty,
} from "@launerlondon/shop-components";
import { useMetaTags } from "@launerlondon/shop-hooks";
import { Link } from "react-router-dom";
import { twJoin, twMerge } from "tailwind-merge";

type SubtitleProps = React.PropsWithChildren & { className?: string };

const Subtitle: React.FC<SubtitleProps> = (props) => {
	return (
		<h4
			className={twMerge(
				"ln-subtitle-sans pt-2 text-xs tracking-[0.35em]",
				props.className,
			)}
		>
			{props.children}
		</h4>
	);
};

export default function Home() {
	useMetaTags({
		title: "Launer London | Handmade British Luxury Leather Bags & Goods",
		description:
			"Discover Launer's British handcrafted leather bags, wallets, purses and accessories. Heritage creations crafted from the finest leather since 1941. Shop now.",
		image: "/images/pages/home-banner.webp",
		suffix: false,
	});
	const accentRowBg = "bg-[#FBE5E6]";
	const accentRowFg = "text-[#915D5D]";
	return (
		<div>
			<div className="relative">
				<div
					className={twJoin(
						"absolute inset-x-0 top-0 z-10",
						"p-2",
						"text-center font-light leading-relaxed text-white",
						"text-[9px] lg:text-[10px]",
						"uppercase tracking-widest",
						"bg-[#343030] md:bg-[#343030]/70",
					)}
				>
					Complimentary shipping on all handbags and luggage /
					Complimentary lettering for a limited period
				</div>
				<Row.WithImage
					image="/images/pages/home-banner.webp"
					className="bg-[#C59988] text-white max-sm:text-sm"
					imageClassName="bg-right"
					contentClassName={twJoin(
						"place-self-stretch sm:max-w-full",
						"text-center",
						"flex flex-col items-center justify-center",
						"gap-4 xl:gap-8",
						"bg-gradient-to-r from-[#241707] to-[#C59988]",
					)}
				>
					<h1 className="ln-title flex max-w-[500px] flex-col text-4xl">
						The season of the oversized bag
					</h1>
					<p className="mx-auto max-w-[420px]">
						The oversized tote bag has emerged as the key style to
						invest in this season. We have statement bags that are
						both capacious and stylish with a timeless essence that
						work tirelessly as your everyday companion.
					</p>
					<Link
						to="/c/oversized-bags"
						className={twJoin(
							"block",
							"mx-auto w-max px-10 py-4",
							"transition hover:opacity-100",
							"ln-subtitle-sans text-xs tracking-[0.35em]",
							"bg-white text-[#091D4D]",
							"font-normal",
						)}
					>
						Shop Now
					</Link>
				</Row.WithImage>
			</div>
			<div
				className={twJoin(
					"container mt-1",
					"grid grid-cols-1 gap-1 md:grid-cols-8",
					"text-center uppercase tracking-widest",
				)}
			>
				<Link
					to="/c/oversized-bags"
					className={twJoin(
						"order-2",
						"hidden md:block",
						"md:col-span-4",
						"xl:aspect-[4/4]",
						"bg-cover bg-top",
						"bg-[url('/images/pages/home-carousel.webp')]",
					)}
				/>
				<div
					className={twJoin(
						"order-1",
						"md:col-span-4",
						"bg-gradient-to-br from-gray-50 to-gray-200",
						"grid place-items-center gap-10 py-10",
					)}
				>
					<Link to="/c/oversized-bags" className="block">
						<h3 className="font-display text-xl normal-case tracking-wide">
							Oversized bags
						</h3>
						<Subtitle className="text-xs">View</Subtitle>
					</Link>
					<ProductCarousel
						products={["3065-03", "3086-03", "2941-02", "3140-03"]}
					/>
				</div>
				<Link
					to="/p/2914-14/encore-pure-white"
					className={twJoin(
						"order-3",
						"md:col-span-4",
						"xl:col-span-2",
						"relative",
						"flex flex-col gap-2",
						"p-5 text-sm leading-relaxed",
						accentRowBg,
					)}
				>
					<div className="mb-5 flex flex-col items-center justify-center md:h-16">
						<h3 className="font-display mx-auto block max-w-[300px] tracking-widest text-[#343030]">
							The Luxe Effect
						</h3>
						<Subtitle className={accentRowFg}>
							Encore in Pure White
						</Subtitle>
					</div>
					<div
						className={twJoin(
							"relative mx-auto",
							"bg-[#FFF7F8] bg-cover bg-bottom bg-no-repeat xl:bg-cover",
							"w-full flex-1",
							"aspect-square",
							"bg-[url(/images/pages/home-feat-1.webp)]",
						)}
					/>
				</Link>
				<Link
					to="/cc/the-hatherton-collection"
					className={twJoin(
						"order-4",
						"md:col-span-8",
						"xl:col-span-4",
						"flex flex-col gap-2",
						"p-5 text-sm leading-relaxed",
						accentRowBg,
					)}
				>
					<div className=" mb-5 flex flex-col items-center justify-center md:h-16">
						<h3 className="font-display mx-auto block tracking-widest text-[#343030]">
							THE HATHERTON COLLECTION
						</h3>
						<Subtitle className={accentRowFg}>
							Our new range of refined luggage pieces
						</Subtitle>
					</div>
					<div
						className={twJoin(
							"aspect-[4/3]",
							"bg-cover bg-center",
							"relative",
							"bg-[url('/images/pages/home-feat-2.webp')]",
						)}
					/>
				</Link>
				<Link
					to="/p/3097-20/judi-burgundy-croc-effect"
					className={twJoin(
						"order-3 xl:order-5",
						"md:col-span-4",
						"xl:col-span-2",
						"relative",
						"flex flex-col gap-2",
						"p-5 text-sm leading-relaxed",
						accentRowBg,
					)}
				>
					<div className="mb-5 flex flex-col items-center justify-center md:h-16">
						<h3 className="font-display mx-auto block max-w-[300px] tracking-widest text-[#343030]">
							Faux Croc
						</h3>
						<Subtitle className={accentRowFg}>
							Judi in Beautiful Burgundy
						</Subtitle>
					</div>
					<div
						className={twJoin(
							"relative mx-auto",
							"bg-[#FFF7F8] bg-cover bg-bottom bg-no-repeat xl:bg-contain",
							"w-full flex-1",
							"aspect-square",
							"bg-[url(/images/pages/home-feat-3.webp)]",
						)}
					/>
				</Link>
			</div>
			<Explore
				autoplay={10000}
				className="container"
				data={[
					{
						title: "Latest at Launer",
						items: [
							{
								title: "The Stunning\nSuede Judi",
								link: "/p/3097-30/judi-tan-suede",
								image: "/images/pages/home-explore-suede-judi.webp",
								size: 6,
								button: "Shop Now",
								bgPosition: "center",
							},
							{
								title: "Radiate in Green\nThis Winter",
								link: "/cc/radiate-in-green-this-winter",
								image: "/images/pages/home-explore-radiate-in-green.webp",
								size: 6,
								button: "View Edit",
								bgPosition: "center bottom",
							},
							{
								title: "Brian and Maggie\nTV Drama",
								link: "/n/2025013101/launer-featured-in-brian-and-maggie-tv-drama",
								image: "/images/pages/home-explore-brian-maggie.webp",
								size: 7,
								button: "View",
								bgPosition: "center",
							},
							{
								title: "Animal\nMagic",
								link: "/cc/leopard",
								image: "/images/pages/home-explore-animal-magic.webp",
								size: 5,
								button: "View Edit",
								bgPosition: "center top",
							},
						],
					},
					{
						title: "Highlights",
						items: [
							{
								title: "Royal Tartan",
								link: "/cc/royal-tartan",
								button: "View Edit",
								image: "/images/pages/home-explore-royal-tartan.webp",
								size: 7,
								bgPosition: "bottom",
							},
							{
								title: "Clever Colour\nCombinations",
								link: "/cc/clever-colour-combinations",
								button: "View Edit",
								image: "/images/pages/home-explore-clever-colour-combinations.webp",
								size: 5,
								bgPosition: "center",
							},
							{
								title: "Patent\nWonder",
								link: "/cc/patent-wonder",
								button: "Shop now",
								image: "/images/pages/home-explore-patent-wonder.webp",
								size: 6,
								bgPosition: "center",
							},
							{
								title: "Accessories\nto Cherish",
								link: "/c/accessories",
								button: "Shop now",
								image: "/images/pages/home-explore-accessories.webp",
								size: 6,
								bgPosition: "center",
							},
						],
					},
					{
						title: "Customisation",
						items: [
							{
								title: "Choose from over 100\ndifferent colours and finishes",
								link: "/customise-at-launer",
								image: "/images/pages/home-explore-swatches.webp",
								size: 8,
								bgPosition: "bottom",
							},
							{
								title: "Personalised\nLettering",
								link: "/customise-at-launer",
								image: "/images/pages/home-explore-lettering.webp",
								size: 4,
								bgPosition: "center top",
							},
							{
								title: "A choice of\nequisite fittings",
								link: "/customise-at-launer",
								image: "/images/pages/home-explore-fittings.webp",
								size: 5,
								bgPosition: "center top",
							},
							{
								title: "Elevate with a\nstatement strap",
								link: "/customise-at-launer",
								image: "/images/pages/home-explore-straps.webp",
								size: 7,
								bgPosition: "center",
							},
						],
					},
				]}
			/>
			<div className="container my-20 text-center uppercase tracking-widest">
				<h3 className="text-lg font-light">
					<Link
						to="/p/2947-10/bellini-forest-green-dark-green-patent-tangerine"
						className="text-[#BBACA0]"
					>
						Forest Green / Bellini
					</Link>{" "}
					– through 360º
				</h3>
				<ThreeSixty
					tip="Swipe to rotate"
					className="mx-auto max-w-2xl rounded pb-[100%] md:pb-[700px]"
					image="https://assets.launer.com/images/360/2947-10/{i}_1280x1280.jpeg"
					count={36}
					loop
				/>
			</div>
			<Instagram className="mb-1 mt-14" />
		</div>
	);
}
