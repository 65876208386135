type Collection = {
	group?: boolean;
	sort?: boolean;
	skus: string[];
};

const collections: Record<string, Collection> = {
	"christmas-gifts": {
		group: true,
		sort: true,
		skus: [
			"0001-01",
			"486-03",
			"620-05",
			"670-02",
			"670-06",
			"685-18",
			"686-17",
			"726-04",
			"726-06",
			"746-04",
			"746-07",
			"746-10",
			"785-07",
			"882-08",
			"882-10",
			"2826-06",
			"2947-03",
			"3009-03",
			"3009-04",
			"3013-06",
			"3050-31",
			"3067-01",
			"3097-27",
			"3121-02",
			"3124-08",
			"3136-03",
			"3140-03",
			"3141-02",
			"3147-01",
		],
	},
	"luxury-stocking-fillers": {
		sort: true,
		skus: [
			"0001-01",
			"010-01",
			"011-01",
			"205-02",
			"205-03",
			"232-05",
			"244-03",
			"281-01",
			"281-02",
			"281-03",
			"282-01",
			"282-02",
			"3119-02",
			"3119-05",
			"3119-06",
			"496-05",
			"496-09",
			"670-02",
			"685-15",
			"726-02",
			"726-04",
			"746-04",
			"746-05",
			"746-07",
			"746-09",
			"746-10",
			"785-01",
			"874-04",
			"874-10",
			"874-12",
			"920-01",
		],
	},
	"gifts-under-250": {
		group: true,
		sort: true,
		skus: [
			"0001-01",
			"010-01",
			"011-01",
			"232-02",
			"232-05",
			"232-06",
			"244-02",
			"244-03",
			"281-01",
			"282-02",
			"496-04",
			"496-09",
			"517-02",
			"726-02",
			"726-03",
			"746-06",
			"746-08",
			"746-09",
			"746-10",
			"785-04",
			"785-09",
			"785-10",
			"882-03",
			"882-06",
			"882-07",
			"882-08",
			"882-09",
			"925-01",
			"938-02",
			"938-04",
			"874-03",
			"746-05",
			"920-01",
		],
	},
	"gifts-for-her": {
		group: true,
		sort: true,
		skus: [
			"0001-01",
			"103-02",
			"103-03",
			"205-02",
			"205-05",
			"228-01",
			"232-03",
			"232-06",
			"244-07",
			"245-01",
			"254-02",
			"254-04",
			"254-05",
			"268-05",
			"268-06",
			"281-01",
			"2910-05",
			"2914-02",
			"3009-04",
			"3011-04",
			"3050-23",
			"3050-33",
			"3050-35",
			"3050-37",
			"3067-03",
			"3085-03",
			"3097-20",
			"3097-22",
			"3097-29",
			"3126-07",
			"3126-08",
			"3129-0",
			"3129-01",
			"3136-03",
			"3140-03",
			"3146-01",
			"3147-01",
			"496-09",
			"670-04",
			"670-06",
			"685-06",
			"685-07",
			"685-16",
			"685-17",
			"685-19",
			"686-03",
			"686-11",
			"686-14",
			"686-19",
			"726-03",
			"726-04",
			"726-06",
			"738-08",
			"746-04",
			"746-06",
			"746-07",
			"746-10",
			"785-04",
			"785-05",
			"785-10",
			"874-05",
			"874-10",
			"874-12",
			"882-04",
			"882-06",
			"882-08",
			"882-10",
			"938-04",
			"938-05",
		],
	},
	"gifts-for-him": {
		group: true,
		sort: true,
		skus: [
			"0001-01",
			"112-01",
			"244-02",
			"244-06",
			"245-01",
			"245-02",
			"281-02",
			"3136-02",
			"3140-06",
			"3145-02",
			"3146-01",
			"3149-01",
			"399-01",
			"486-02",
			"486-03",
			"496-05",
			"496-07",
			"496-08",
			"610-04",
			"620-03",
			"620-04",
			"670-03",
			"670-05",
			"670-07",
			"717-08",
			"717-11",
			"717-12",
			"726-03",
			"726-05",
			"746-02",
			"746-06",
			"746-08",
			"746-09",
			"785-03",
			"785-09",
			"794-02",
			"805-05",
			"805-18",
			"805-24",
			"805-31",
			"805-34",
			"806-01",
			"806-07",
			"874-06",
			"874-08",
			"874-11",
			"882-03",
			"882-05",
			"882-07",
			"938-01",
		],
	},
	"tech-gifts": {
		group: true,
		sort: true,
		skus: [
			"0001-01",
			"244-02",
			"244-06",
			"244-07",
			"245-01",
			"245-02",
			"3009-01",
			"3009-02",
			"3009-03",
			"3009-04",
			"3145-01",
			"3145-02",
			"3146-01",
			"3146-02",
			"3148-02",
			"3149-01",
		],
	},
	"travel-gifts": {
		group: true,
		sort: true,
		skus: [
			"3140-01",
			"3140-02",
			"3140-03",
			"3140-05",
			"3140-06",
			"3141-01",
			"3141-02",
			"3142-01",
			"3145-01",
			"3145-02",
			"3146-01",
			"3146-02",
			"3147-01",
			"3148-01",
			"3148-02",
			"3149-01",
			"3009-01",
			"3009-02",
			"3009-03",
			"3009-04",
			"3136-01",
			"3136-02",
			"3136-03",

			"670-07",
			"670-06",
			"670-05",
			"620-05",
			"486-03",
			"281-01",
			"281-02",
			"281-03",
			"282-01",
			"282-02",

			"659-02",
			"205-03",
			"254-05",
			"686-09",
			"686-10",
			"686-13",
			"717-07",
			"785-09",
			"794-01",
			"874-06",
			"882-03",
			"882-05",

			"746-04",
			"746-05",
			"746-07",

			"0001-01",

			"805-22",
			"486-01",
			"806-01",
			"245-02",
			"496-04",
		],
	},
	"wedding-bags": {
		skus: [
			"3113-13",
			"3113-12",
			"3113-14",
			"3129-04",
			"3097-25",
			"3129-05",
			"3011-11",
			"3011-12",
			"3011-10",
			"3129-02",
			"3126-09",
			"3097-19",
			"3097-24",
			"3097-05",
			"2914-14",
			"3050-22",
			"3113-11",
			"3011-04",
			"3011-08",
			"2910-07",
			"3124-12",
			"3097-14",
			"3051-07",
			"3119-05",
		],
	},
	"oversized-bags": {
		skus: [
			"3086-02",
			"3086-03",
			"3065-01",
			"3065-03",
			"3128-01",
			"3065-04",
			"2941-02",
			"3086-04",
			"3009-04",
			"3140-07",
			"3140-03",
			"3145-01",
		],
	},
};

export function hasCustomCollection(slug?: string) {
	return slug && slug in collections;
}

export function getCustomCollection(slug?: string) {
	if (!slug) return;
	return collections[slug];
}
